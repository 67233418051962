import "../../core/src/components/tags/tag.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/tags/tag.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61W247aMBB95yv8stIi1SgEtt016pdUVWXiSTJdY0e2w2VX/ffKdgKJybJC7QuCsed2zpkxi19LWT1XzVtO3meENNqiQ60YMSC5wz1sZoQcULiakWWWPfifNWBVu8vvHSra21Z5c9zM/swWfdxViCvQNpKfGCklHL0Pl1gpig52lpEClAMzclsHN70HU0p9oEdGahQC1ObDItOqKt4w8pxU8xTC+iLowfgL/nPTmwQaKGLkQst2p64qDdes48adU6yTFF+nG44B6aWqDzAYtse3VsvWTba340ea8OIMV2dXKUm2yC3JFitLgFugqKhu3ajWnNUeYpIUP4i95+aR0v44m4/cvyWaGdbrdMNI5r9JKF331cQesqmGdMMLdKfudKoXb9/y4rUyulWCEYkKuKGV4QJBuceXTED1Jbo23IByJHv40rXAyxIV0NAvLbTUhpYoJYg5WWcP89uwxEbPFS5Ht5/DYd9OnkdyrwTgp6QDNbvB/u/WOixPtNDKgXKM2IYXQLfgDuDlP0zMJLeOFjVKEXm7pPAUdiFGPi93jOPnxRDScCFQVYxkvaoDtmyMuoOjo43BHTenCP48kKmN6NlIPIZHY3JefgjuOC110VoQ351p4Wdo6ka0NPUgHI8bQStHLb4BI6hqMOgG9Xl9tdYrtbka5XU0dTf7gRwZrTtJYMRqiSKV8Ljn88EEVsN5zzuo0xEKMPc7kxGQEhuLYW4ONTqggT5GlI5rbwDD9gYMZ47XzZEs82sQunLuTX9pYJUlS7RIyxkBFe27+TSafYzlfFqgCasjBgN3kapxyn1Ovey2fpHGq/MPX4Di/ypUfI5FTZ/m6X69rGXrsHg9jZYSKhmcw/gPckHI1eksUdk0fE9RevFhrA2q1y7/bWIGOcvRUz986O8XVGv9wIGEwnmzglGm6t+W3+XoPIjJHOb3QVS0xnolNBqv/gBV3Rv0fmtjDF4zj+lf66KEVs0JAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hoverMaxWidth = 'var(--_1lg8gpz0)';
export var innerBackdrop = '_1lg8gpz7';
export var innerContainer = '_1lg8gpz6';
export var root = '_1lg8gpz2';
export var showMoreTag = '_1lg8gpzd';
export var tag = '_1lg8gpz8';
export var tagColorVar = 'var(--_1lg8gpz1)';
export var tagIndicator = '_1lg8gpze';
export var tagInlineMode = '_1lg8gpza _1lg8gpz9';
export var tagInnerWrapper = '_1lg8gpz9';
export var tagLabel = '_1lg8gpzf';
export var tagLabelMode = '_1lg8gpzc _1lg8gpz8';
export var tagListItemMode = '_1lg8gpzb _1lg8gpz8';
export var tagRemove = '_1lg8gpzg';
export var tagsContainer = '_1lg8gpz3';
export var tagsListContainer = '_1lg8gpz5 _1lg8gpz3';
export var tagsScrollContainer = '_1lg8gpz4 _1lg8gpz3';