import "../../core/src/components/affine/auth/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/auth/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61WwY6bMBC971dY20v34AhYkmwd9dLfqKrKwQN449jINkm2Vf+9soGsIbABaU9ItufN85uZZ1a/4+x1mx7SCP19QOhIdcEltqoi6DmqLruHfw+r7kgcHCGI1lbt3kP2ylp1JCh1QQhZuFhMBS8kQRlIC7oHlUxkQyhTQmmCTlR/xZjmOZeAPZiBTElG9Rv2R57c4VxJiw3/A4MAv34x/oxwCyXworQExat1j8fzch4lL0rh0HCuNBRa1ZJhDexzCCWIekpj6QWXh+vlezErt9XE1dq4wErxRvRlUKkHqZThlitX5L1RorbgcHTDONoNFMPJZtAo61DVrjFu2mkz76II7Wl2aHQmyGoqTUU1SOu3lGagcQsy2FxcjCRpal8b0NiAgMwSJJWEHu+t5/0huDk+Le7l+6J3SkY9Oi+eTneF7ba5AuOmEvSNoFyAX3BfzLiGrEmSKVEfpdt5rY3luaMiLUj7Pq8I+QHG3MLRhMsfusQ3z+cm/wdZCloR9NLwHq3Hfa2D/NPz46WvND+OmMi5TbmJ+uruPdqZM1sSlK5nmVvWi4mmY+Z1Uau2gNz5xWbanvaihtsbpYMbMc9unM6Sqk30xpyh6/FbRzeOsml1Hj4tw1aD8VabQ+HaYlHQYgNKM720qjXgc8ktDNwKjxZJ026nGXvKGJcFQVH3dLaepinjtbk+qEsrEcr0k1FLMTd4D4LDCfT3R6trePzl9bsl/OV5m8bruAeSj2vtZzdu53SiJYZah6XuQgelbpaD9EVjugIuBMUhaBRIdo2uLsgowVlf+lOCBX0Djbk0nMGPLsh9+h5STnrIKRk4+MxnPwDnd8Fbj5r9R3HbvEG617vp3HO7/BqHubhBjJgY2Mm3aby1/wPN7X4DtQoAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accessMessage = '_1cj74k4d';
export var authMessage = '_1cj74k42';
export var authModalContent = '_1cj74k40';
export var captchaWrapper = '_1cj74k41';
export var errorMessage = '_1cj74k43';
export var forgetPasswordButton = '_1cj74k47';
export var forgetPasswordButtonRow = '_1cj74k44';
export var linkButton = '_1cj74k46';
export var resendCountdown = '_1cj74k4b';
export var resendCountdownInButton = '_1cj74k4c';
export var resendWrapper = '_1cj74k48';
export var sendMagicLinkButtonRow = '_1cj74k45';
export var sentMessage = '_1cj74k4a';
export var sentRow = '_1cj74k49';
export var skipDivider = '_1cj74k4f';
export var skipDividerLine = '_1cj74k4g';
export var skipDividerText = '_1cj74k4h';
export var skipLink = '_1cj74k4j';
export var skipLinkIcon = '_1cj74k4k';
export var skipSection = '_1cj74k4l';
export var skipText = '_1cj74k4i';
export var userPlanButton = '_1cj74k4e';