import "../../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE8VW227bMAx9z1cIBQo0QFU412Uqtv8Yhj0oEm1zlSVDkpt0Q/99sGS7cuK061BgbzHNy+HhMZk7WbkMbUZ+zwiR6GrFnxjJFRzvZ4QIo4xl5JHbG0p5nqMG6uHoaW2x4vaJBod561oCFqVnZLOuQ2ibgrrSon5gJIvJtOeoGXHeovCt6YDSl4wssuy6feQKC03RQ+WCF3hRtnZvuXbo0WhG9lw8FNY0WsbiJLtbultiai7QP4WnNsQ8gs2VOTBSopSgA4DGurYdCTlvVAdAKSpKrgtgfY772fPsrqOFlW2iQM5p4RNaguPAx0uG75J7ToVC8cD3Cr5428CPkLCHUxvUHmwStAjve0bXm8jo3lgJllousXEtadF8NrQRiwJi7hT/ZeQ0R6VAzmO5dn5cthyO/CvQTfcmOFb8SLtBrrYdqAp1b1tG29DcMjRXm36gFhT3+AgxlS1QUwW5Z4TuYq6eh14lpvEKNTCijQ5RNZcSddGF7WK1yLu0vCgS3nsMbA+5sRDnYLQH7Rm5urofIeN7Z1TjQw1vakY2EUCQY25sxeJPxT18u6Gb7DrwEWFkib7X40a6x1TV8dUFLb+hvFpxAaVRMtHfmVqWsWaXeoCXqD9CuE2/gjc4DMJJmRwmdVptcTFbYkRdnFQISV8g323+Lc0UxFUHsRfpFOD+EyZfCcvROk9FiUpG/Y40t9iOJB4iFJ8MsLH+bhSwSl0Yycjmfd92wet+IQw519Mr/UKG83WdG+2pw19wIrdgL+kq3XKb6VohqUQLIqpcGNVU+gxFrO259fdjFiKtow0juBI37SYglGzX9XHe1wkzI+RQogfqai7CgjhYXrfmxoGlDhQI3++NAfw2ymziYLxKgavmg8+hU9U2y14BkcAMR/SlJCiFtUP3Qo0DlSc3cAD76SLYi7fagTBajq/1q50d3V+y+s9d7N6nzbeVON0+CqPTpk80PsD5/HFwOu7eNYxE8Scnrz+no/9JA2z+f2FPrIzW0oojFclpdwP8/bvg/2ycx7yt313rUAy0fHWy4uNqxOVFw6rdTv/Hff4DGZpnLE4LAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = 'dms0irb';
export var dateCell = 'dms0ira';
export var dndCell = 'dms0ir2';
export var dragPageItemOverlay = 'dms0ir1';
export var iconCell = 'dms0ir8';
export var operationsCell = 'dms0irc';
export var root = 'dms0ir0';
export var selectionCell = 'dms0ir4';
export var tagsCell = 'dms0ir9';
export var titleCell = 'dms0ir5';
export var titleCellMain = 'dms0ir6';
export var titleCellPreview = 'dms0ir7';
export var titleIconsWrapper = 'dms0ir3';