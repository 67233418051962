import "../../core/src/desktop/dialogs/setting/general-setting/billing/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/general-setting/billing/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61UwW7bMAy95yt4GbAeFNhZmqYqsF8ZZIm2OdiSKsmLs2H/PlhJViux67bbyZAoPj7yPXr9LS9s82xkDr9WAIq8bcSRQ9lg/7SC+GWKHMpARnOQpulaPUREQ5VmFLD1HCTqgG64roTlsNna/mn1e7W+oG8i+oFUqDnkWfYpiX6Zrv2984HKI5NGB9SBg7dCIiswHBAjByuUIl1xyDc2phTGKXQcctuDNw0p+CHcZ8ZEWZJGdgozaRrj7l7eMycUdZ7D/or2NhIb+HDIIYMsid6PmxJdMANiK1xFmgVjb+F2JzijA/P0E3lKLt7XbBeJxcMBqaoDh12WFn5YximEx7skaR+TxvT+qqSErtCZzjOPIZCuYK1FizEhzuqqAjpnXqY4qvE4mxKwD8yjNFoJdxwp8GoXvU/xxXLjMeVqfvfD/F7hha2thSc/oiU754fH1tDJ2SMWxXtXZXon5HthWtKsPrf0cDb8zBberM4lNKqvPrp1Zw8VJgTT3rocl0Xy7YzJAZrh0aXLzbnLWeWso3bspxGN8s1eSUtmp5IJt+2CgW6NnRD5CmJ2MQontLreh8S4I6jqn37SqFWCVl9PKN9P9D6py+7jutB/1GVpsSd0+QMWaftm8QYAAA==\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var believerHeader = '_1bplqocd';
export var believerPrice = '_1bplqoch';
export var believerPriceCaption = '_1bplqoci';
export var believerPriceWrapper = '_1bplqocg';
export var believerSubtitle = '_1bplqocf';
export var believerTitle = '_1bplqoce';
export var billingFrequency = '_1bplqoc7';
export var billingHistorySkeleton = '_1bplqocc';
export var currentPlan = '_1bplqoc4';
export var currentPlanName = '_1bplqoca';
export var history = '_1bplqoc1';
export var historyContent = '_1bplqoc2';
export var noInvoice = '_1bplqoc9';
export var paymentMethod = '_1bplqoc8';
export var planAction = '_1bplqoc5';
export var planCard = '_1bplqoc3';
export var planPrice = '_1bplqoc6';
export var subscription = '_1bplqoc0';
export var subscriptionSettingSkeleton = '_1bplqocb';