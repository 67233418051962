import "../../component/src/components/auth-components/onboarding-page.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/auth-components/onboarding-page.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61W247bIBB9z1egSpU2D6xwLt7Uq0p97TdUVYXN2GaDwQIcJ6323yvwZbGTNJu2L0k0DOcMZ255/BFt422zbgj6tUAopdm+0KqRDGdKKJ2gA9UPGNM85xJwcFxrXlF96tyWzwuESuBFaRMUEXIonYFxUwt6SlAu4OgM7hszriGzXMkEZUo0lXQn6gA6F6pNUMkZA/m8eF08jqF9Y9RSzA2uaKYMBgGZ1Up+/mB1Ax+++8grqgsuE7SrPVWqNAONNWW8MaN1CDGjInvwcSKMorg+LieEkUesKWNcFtiqOkGrTYcwGHUHNJqV4d2bjOXZ/uRs/h65UwgqeCExt1CZzh2DZO4At5DuucW0rrGGwl9jmhbTwEelWi6Zaq9oNXkZufAsbytonXhxJhSrECFBK3JdgFRZq6oe7e8rKyBfe/I75HxpjOW5w5IWpMs8SAv6TOk38/iyHamPaEXcZxSTmQqbvuaOuOXMlgmKydxl+8+xmppmgFOwLYAcM7KOO8UrLvFQz5vtnD327LmSFue04uKUoK/DE73V8J8wy4G3lzhavjnZk4AESaUrKkZr27PGxGdWuLtDJOt5vTzdK8N5BxhLtZ1VZI9QaDczognj7n2MWrXvoRs6vDsqNZd7X9EB4SdPeKWeArE7qMlFcyj85YstoenQLMtLEQwzb2ja3QydvpXAtWSn1MDyLK9bMn1f6oH6Qt+u5qWWvfHMKuM93AEQC3Q0IPJpPgJH6GbQOHJoapRoLExmrVQSusnEXS4wHEBaM9gDtDzYH9O5HjgV95ZxUDwBTHk7J5ZbcSEpTxeabTOH57fhSxxPtvWa9BP8Zmuv5q39ckWTP9EfzfS/Qt/MXq3+d19oESEfL47FcCv+l62yv9qCFo4WG8iUZNfuJgduuAV2P8aXChinyGQaQCIqGXoIFkpEXPEsPexs/wVLym0nN3L9tnKCvIbum7n7xvn7zE6W1yD1682onuLdWVBpz3IO9hsGQOOIXQoAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var button = '_1565u3uc';
export var buttonWrapper = '_1565u3u8';
export var checkBox = '_1565u3u9';
export var content = '_1565u3u5';
export var disableButton = '_1565u3ue';
export var footer = '_1565u3u2';
export var header = '_1565u3u1';
export var input = '_1565u3ub';
export var label = '_1565u3ua';
export var layout = '_1565u3u0';
export var link = '_1565u3uk';
export var linkGroup = '_1565u3uj';
export var onboardingContainer = '_1565u3u4';
export var openAFFiNEButton = '_1565u3ud';
export var optionsWrapper = '_1565u3u7';
export var question = '_1565u3u6';
export var scrollableContainer = '_1565u3u3';
export var thankContainer = '_1565u3ug';
export var thankText = '_1565u3ui';
export var thankTitle = '_1565u3uh';
export var windowsAppButton = '_1565u3uf';