import "../../component/src/ui/notification/desktop/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/notification/desktop/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE4VU227bMAx9z1dwAQYsDyrsXLpOQb9kKAbFom1uiiRIdJxs6L8PluPGcd3t0SR1bqL88CPfNF6djo/wZwFwcEFjEEFpaqKEJ3/ep+pZxFpp10o4qfBFCFWWZPFaFPmqm3InDKXpZmrSGu1+8bp4GOC/zsGTrTEQ72+dljTXEvKBNxUjXwxKiM6QTmVV/KqCa6wWhTMuDKIGrmw1Ojw7sU4Ts63Oy0j3U9LtldZkKwn5Y6ds1P+W+pqiN+oioTSYlCtDlRXEeIx9UURWITn92USm8iIKZxktS4heFSgOyC1OQlMJvEaqapaw3vap/JusQMsY7mAOCeYa7Yco3nQyJigARxUqsiL0GvJs4v8A8VSN8fMs+7wfqR6+Z8Pe3oddjIGy7lSKrgrdUuXzOwbAeGZxa6Ax5CPFrtXWxChSwBKsa4PyCdVZFu1V4DZLTKbb52nUaTDSb5SQ7/rSf/LQycJkaLo0mIZmE9mkzfQuEpOzEgIaxXTC+72XwEHZ6FVAO3o+nUeLd1Tld61YidI4xc9LDg0uX/qVfqNQh+hMw4mCnR/0Anwkv/pY/m4Fn+joXWDVCRsdqsfBJJrdu4zv/i19/bh6fznrXk/vrCWuBRXOvsCNq2+ld/G8ZGLz5rp/yMJgyRI26Zpf/wK75Eg3BAUAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var action = '_13upavmd';
export var actionButton = '_13upavme';
export var actionTextColor = 'var(--_13upavm3)';
export var card = '_13upavm7';
export var cardBorderColor = 'var(--_13upavm2)';
export var cardColor = 'var(--_13upavm0)';
export var cardForeground = 'var(--_13upavm1)';
export var cardInner = '_13upavm8';
export var cardWrapper = '_13upavm6';
export var closeButton = '_13upavmf';
export var closeIcon = '_13upavmg';
export var closeIconColor = 'var(--_13upavm5)';
export var headAlignWrapper = '_13upavma';
export var header = '_13upavm9';
export var icon = '_13upavmb';
export var iconColor = 'var(--_13upavm4)';
export var main = '_13upavmh';
export var title = '_13upavmc';