import "../../core/src/components/page-list/page-group.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/page-group.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE6VUYW/bIBD9nl+BIk1qpBE5TpelTJX6P6ZpwnB2bsVgAc6STfnvk8FebcdO1faTMXc8eO/e3dMznHPLS3Dk5wZTK7LthvxdEJJ8Ch9CTMUF+jMjybfwfwAsDp6RpDo1G5cFIZvkOnszzD5ye0ep5RJPVBileOUwU0CF0R60pzFvFREvi6frZ6Uzz/rIRdNPnyN6Way7xyThjERXKX5mJFcQcpovlWhBeDSaEWFUXeomUvCKkV2D1EPZBhRzBJsr85uRA0oJepjyXXLPqfPcw+PSVKCXP8IprrHk8ZaXwiXrrSPAHVDU1NT+BpRQxoGcB0tvg92/S4D7kQBfplG4wkJT9FA6RgRoD7bZrriUqItQEbLZVaew2MXydNVK9/G/qTdHzYjzFoVvtjIungtrai07m/A8Rw30JUAriyW358Y6xgaX1A4sdaBAeEa00TBkwA5N/QKPWfyQEiFpjkqBXA1AduF4wz7YuRf5GiKits5YRiqDUYtexj6eNdpTh39gdHPcL1dREdWADOIeTp46EEbLPuse/sPH8SU6HlvwCp1PG2DYijN2+FU7j/m56+x+6EqwVxic3GrgobblM2Ml2DBMate6t29Dsh/5mb/RDatJL43U6U+6XnY2DCYjkh2HqaqgMLrncG+5dhgbNqxzY0uSrNP59s/iLGkHLMjHZc6Vg3ac/AdhxJpm3Nw9JBKKV9hmn8kNfG/rDn5GEPGmYXLDPaOyp1HIeZV6MqM+gMWhVuIWl2up6IRWLUTbSDNqJOttsGf0PIUjaO8mRpZ4h0n/AVo3mmcnCAAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapsedIcon = '_1i2rcb3b';
export var collapsedIconContainer = '_1i2rcb3c';
export var collapsibleContent = '_1i2rcb33';
export var collapsibleContentInner = '_1i2rcb34';
export var header = '_1i2rcb35';
export var headerCollapseIcon = '_1i2rcb37';
export var headerCount = '_1i2rcb39';
export var headerLabel = '_1i2rcb38';
export var root = '_1i2rcb30';
export var selectAllButton = '_1i2rcb3a';
export var spacer = '_1i2rcb36';