import "../../core/src/components/tags/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/tags/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VWbY/aMAz+zq/wTpoEu+XUctxb0PY7Jk2nKaRum12aRGkK7ab99ylpoS0UdEzaB0A4jv34eWy3dz/iN6fTJvqeMMcIFsY1X5yt8BV+zwC4ltpS2DI7J4SlqVBIjGQccy0TtCScL9azP7O7LlAc7iWiNJI1FFKJ9XoG4ZckwiJ3QivqA1eF8ic7kbicQhxFH/3fjBkK8dLUw6DLEDQcLaPx0f10vuD7aPrcO+st/vskaR9sFYIZliRCZRQi72p0KVrMFiVzYove6rShwJnkc3J/92BquO1IsiwRNTHaGLSEKZ5rS3IUWe4W8AlIvPC3JaaOQntzACfEOx8meC3gFuJHU4c4eos2lXpHIRdJgmpYy8MFYlZniTnUHkem7nQA2DD+llldqYRMdUTucRx6AaAQqquZwupEyjHAjba+kXy9VUnfm3G7JJI1aMnAz1hRMNuMuvEx5Ah1ZtbzFJ8WGVQumM2EokB6U4uMgtIqSK4rJ4XC3pBq5UgpfiGFeM+oN6WsELKhIFSOVrh1P0cDy2mBzjJVGmZRuVEJlA4m7h+n8unaqQxt8twW1c+Dt+w/LWt1L3QUtca+KVnl9LT2TyeD1ns9T2P9WZVOpA3hWjlUjkJpGEeyQbdDDJiZFJkiwmFRUuCoHNpT+EfCjTgM9rpcHHx2XW0PUbQ+w7vD2pESuVYJs80E9X3RbWEXk5fF6O5LuLEnlNT9nJ/bS0NFlntFejGnYL38tznsMkcjFVZjHOx9nWn17iqJ9xzcr44pAOCVLX0lRot9gKPqjzG2D8dU86rEZPB4vGovTnHfVn8YodU5jY4Q3HgIN68Tl69YLJu2uQzjwjXdDFJRzg85acD/+QBiuYAzd+NR3BZr6ZjDL9qger3gzCcWdH+ajNdE2M3vmeEwRlfN63HHTImQXBQrDaeB61TboqNdMoff5qvwvO59s2vX8eRYn1+0WStB+xJRcqulJMwiI1uBO6Ote4WvkIjtGIZjG4nwQRTeg4Vm+QvXUVn+HwoAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var inlineTagsContainer = '_1ktofy3';
export var menuItemList = '_1ktofyg';
export var menuItemListScrollable = '_1ktofye';
export var menuItemListScrollbar = '_1ktofyf';
export var searchInput = '_1ktofy6';
export var spacer = '_1ktofyc';
export var tagEditIcon = '_1ktofyb';
export var tagSelectorEmpty = '_1ktofyd';
export var tagSelectorItem = '_1ktofya';
export var tagSelectorTagsScrollContainer = '_1ktofy9';
export var tagsEditorRoot = '_1ktofy1';
export var tagsEditorRootMobile = '_1ktofy2 _1ktofy1';
export var tagsEditorSelectedTags = '_1ktofy5';
export var tagsEditorTagsSelector = '_1ktofy7';
export var tagsEditorTagsSelectorHeader = '_1ktofy8';
export var tagsInlineEditor = '_1ktofy0';
export var tagsMenu = '_1ktofy4';