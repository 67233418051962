import "../../core/src/desktop/dialogs/collection-editor/edit-collection.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/collection-editor/edit-collection.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE51W23LbIBB991fw0pn2gYykuo5DvgaLlUSCgEE4ktvpv3e4iIpYji9vHmB3z549e+QncypH/VygPxuE1AeYRqiRoI4zBvJ1g5CFyeL/FyAE1wMf3NXYcQt40LQGgqQaDdWvm7+bp5Cy9CkZH7SgJ4IaAZMLaqkmaK/9byp4KzG30A8E1SAtmEWCaj3B23GwvDnhWkkL0hLkAeAD2BECZE0Z47IlqCr0hKptKHZQhoHBVmmCSj2hQQnO0Ac13zGmTcMl4PikVkKZHy7G1cSuL4JCdxF/WbmcCelPj7RR0uKB/waCyljTH43A284StC0KdyZcpS6eVVV46EuSHI1nfoBaSUbNaYEq9bfVEyp3j7aX4G8DfAETQVVqm3EDteVKEofu2MtFFQGNvZ3FWT14WgornZ4IokerFoB+XVRjRujOEZqCdj4ocVPMIruD2pTreV16q3qNklhOPIogzjYfeBEOz5tLxfd3FR85sx1BPZ3mjfBzovV7a9RRMrzW/+JaG97n8lrjPag+Sm1172dt3C6LeGAo48cheUIm7r2eArGJnJf7J1MW56PZro0mX2p6UYRn1S/vy9Lt/KuhM1y+E7RU7iFXrtON5zoRfkfBsMXlFYnVj1rrVfde9jG34McYekkI2MJzyjt7/KIxWG9s1Urm2im4WQ++DGRWTlkU3xZ5Wp+np6blMlplWPv0ovMv4up+iub+bm1rP69qCnk7+/58ZT2PGOL7zaBmHPYkgCBuqeD1mXPvM+cWuf79V63KvmqZR6S4PvDMJY5M7nOWpb+/YoWd00a4wQ0XAtiyb5VDy/5QHAcweAABtXU2KOGTycx850PYPvDBjywclLWqv/nz/g8lSHeU2wkAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionButton = 'ry1wp7m';
export var bottomButton = 'ry1wp7l';
export var bottomLeft = 'ry1wp71';
export var collectionEditContainer = 'ry1wp7f';
export var confirmButton = 'ry1wp7g';
export var ellipsis = 'ry1wp70';
export var includeItem = 'ry1wp78';
export var includeItemContent = 'ry1wp77';
export var includeItemContentIs = 'ry1wp76';
export var includeItemTitle = 'ry1wp75';
export var includeListTitle = 'ry1wp73';
export var includeTitle = 'ry1wp79';
export var previewActive = 'ry1wp7n';
export var previewCountTips = 'ry1wp7j';
export var previewCountTipsHighlight = 'ry1wp7i';
export var resultPages = 'ry1wp7h';
export var rulesBottom = 'ry1wp72';
export var rulesContainer = 'ry1wp7e';
export var rulesContainerLeft = 'ry1wp7d';
export var rulesContainerLeftContent = 'ry1wp7b';
export var rulesContainerLeftContentInclude = 'ry1wp7a';
export var rulesContainerLeftTab = 'ry1wp7c';
export var rulesContainerRight = 'ry1wp74';
export var rulesTitle = 'ry1wp7o';
export var rulesTitleHighlight = 'ry1wp7k';