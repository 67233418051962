import "../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE71W0W7bMAx8z1cIBQo0wFQ4bZJ1Krb/GIZhUCTa5ipLhiQ36Yb++2DJbmTHSZut2JtNi+TxeCJ9/WNRLcvGFBn5PSNEoqsVf2IkV7C7nxEijDKWkUduryjleY4aqIedp7XFitsnGg7M26MlYFF6RlbLOri2IagrLeoHRrLWskXpS0YWWXbZvnKFhabooXKMOG/BizLm1J6jDjYUvjV5y7VDj0YzsuHiobCm0TImJ9n1jftATM0F+qfw1rqYR7C5MltGSpQSdIjcWNeWIyHnjfL3s+fZ9QsBrGxdAg3jFCMCwsGXytMY3yT3nAqF4oFvFHz2toHvIWSfujaoPdiB2yKc6PlbriJ/G2MlWGq5xMYxchetBx0asCggBk9LOA6e5qgUyHnM1jaLy5awwfkKdNN9CQcrvqNdI2/XWQRVoe5tN9GWVHcTqqtN3z8Lint8hBjMFqipgtwzQrsSeyJ6nZjGK9TAiDY6eNVcStRF53YX80XqpeVFkVC/R8E2kBsLsRlGe9CekYuL+wE2vnFGNT5k8aZmZBUhBP3lxlYsPiru4esVXWWXgZMIJNX4clhK95rKOH46It5XBFgrLqA0SiYyPBDMTczZhQ7w3sBSkEfK1Us3xvEWR+MlRtTFQY4Qdg/revW3gaZg3nYwezlOgd7fV/KFsByt81SUqGRU6kBdi/VIzsFH8UkXGzHcjVxu00OMZGR13l0ueN3ehlHU5fTEPhLjcBrnRnvq8BeMxBXsJb0djrbVdLYQVqIFEVUtjGoqfYAjZvfc+vshE5HewVQRXImr9u4TStbLejfv84TuEbIt0QN1NRdhJGwtr1tz48BSBwqE7ydFAn8dRTexE07S4Kr5y5ltp7B1lp2AkQANe3KfEpTC2qHbk+NA5cnmS+B+PAr36EJ2IIyWw5V8sradeyOz/1DH3XkafV2R0wSgMDote6T1BNCn9wPU8XdWQxLlj5Zdv0oH/0gJcP7/gB/+2k0Mj9bSCiQVymF1py/l5qyafjbOY97C6pZ3wABavtJy8X5Z4nSjYR6vp/9yn/8A4FLcglILAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_1m4huogb';
export var dateCell = '_1m4huoga';
export var dndCell = '_1m4huog2';
export var dragPageItemOverlay = '_1m4huog1';
export var iconCell = '_1m4huog8';
export var operationsCell = '_1m4huogc';
export var root = '_1m4huog0';
export var selectionCell = '_1m4huog4';
export var tagsCell = '_1m4huog9';
export var titleCell = '_1m4huog5';
export var titleCellMain = '_1m4huog6';
export var titleCellPreview = '_1m4huog7';
export var titleIconsWrapper = '_1m4huog3';