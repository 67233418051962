import "../../core/src/desktop/dialogs/create-workspace/dialog.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/create-workspace/dialog.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VT0W7bMAx8z1fwZcD2oMLOvCxTPmaQLdrhZlOCRCdOi/37IDtt7cZr0QJ7MiCSd+Td+e5njueLK8sMHjYA3kUScqwhYGuETnjYAHQmNMRKnNdQFH44bP5s7h4H83Gwdiwq0j1qOJnwWSlT18SopvfuS4KpXOvCi7rgIMoH6ky4qLFhbB3HzkjNUTTssmzBuB0ZLUXfmouGusXheUsNeeYHWE58XZ9IX2UpYDXdXLm27zhVGuM17P3Y5Y21xI2GfHsDXHwIeLcAnnRNWy+gv02GzMkPa1ympYYVCXZRQ4UsGNJz6YLFoIKx1MenW0pT/W6C69mqNTdm5YiVY7t0pSNWx6speV5cr1iJzOyM3XsV+tVHoTrRsiDL/KYzWTkmqbJPT1pOuswIv7+dx9JEfG8iby642TN6U6EqUc6IvFhp//ZKQ3x9oVU7zKCukmz3+xcy/PgfnDN8M+I/OlLs/pHOG5VG45HtK9F9TpQpo2t7wSnQIq7T6QcECFMIs6md0qTCE7JEDew4RfAvokjYBdoEAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineCloudWrapper = '_1ewyobb4';
export var avatarWrapper = '_1ewyobb2';
export var card = '_1ewyobb5';
export var cardDescription = '_1ewyobb8';
export var cardText = '_1ewyobb6';
export var cardTitle = '_1ewyobb7';
export var cloudSvgContainer = '_1ewyobba';
export var cloudTips = '_1ewyobb9';
export var header = '_1ewyobb0';
export var subTitle = '_1ewyobb1';
export var workspaceNameWrapper = '_1ewyobb3';