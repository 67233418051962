import "../../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61W227bOBB991fMBihgA6EhKYmbyihQ7C/svhSLYDGSRjYbitSStGSnyL8vSMqyLF+aFHmyMNdz5kZ/e6ZdqbEiA//GebX4b/fjHn5OAEqtKv8BoGrMud2lEC0nAK8TAKvGqjioXiffTgM+XA8IYDVKUypdpeFToKXvU5Z8moHJUdA0mn9ZzH6R/FKYaB8knl3BuPAxT0P/FsJzVD8A4+dxHc8Hi6Po02mxLiW+ku/xbfmityYbAHudzPdZMu9TK8MtVzKFkm+pcFZcGrJdBzLMn1dabWTBciWUTqFBPWUMy5JLYgN1pQoUwcjnemFcFrQdOXTSYO3tUPIKA4LDIsQPUWWgVLpFXZjlEey582BSSfIEBv5ONrKtVMZFMPwFlSZhAnekWXDpEQ7C5W+pWMFNLXCXQilo6wkKvpKMW6pMCjlJS9qJf2yM5eWO5UpaknaoelvphsD+KdBih/wplGWY1UFhJD3WGouCyxXLlLWqSoFkMzVYEkNNyDyTTncLSVRvz2YqN0Iwk2si+fXG6g3dhKxd8BQi+INXtdIWpT0O8I72dbYlFvSX29K/VT32OZy6MDL5JuM5y+iFk55G8/vkFqJbiOYPj7cQH88bK7kQrpqUHk1aYLgPfPcE1/DMG8swt7wJbBpOLfOb50eESazoAPLLOXZG8IL+9AW/RO4zRPPkzgChoY+hMEj6Lgbooqfp2EiJYtqTnF2isfi4Hl2HgBchPL63kn3hCh+S9UWNUlgeCeKxIAmClhd2vV/l3tvTXBNfre1YF0pQccnO65Ogx22vz1Hk7sgXzRoY3CV+aYNJl/1g0QLbrzVAprbM8Be/sZnSBWmWKX+0SiWtU9HoCHl5hoZmvVXbobiP/P0TzmyPLJ4vlsOz4DJDcl/7HIdDqkmgm8D3vTiqIS1wx2qUNHx5PKs1Fqod+daqdj6dsrP1rDUWfGNSiJMATW2s43G4SZfP3rUJiaOoaU/GxEnXJ7PSSwdt6/2H3e4NR9ijo3ml0VuFmVFiY32JrapTSDqqOkR1nfmtt6cMf1auzcuaLU7HZXF2XO4fAl294rJ/okJXBjlXPmerdMEyTficgv9hTuJ3bs0tMVNjTinUmlirsfZ9bUiXQrVecOy177Gn+PWmRGHo5unk7efukWbUkLRmPB7HruPJGPvixqrl5PV/tBMFnAkMAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1cm6qyje';
export var heightVar = 'var(--_1cm6qyj1)';
export var minHeightVar = 'var(--_1cm6qyj2)';
export var modalContent = '_1cm6qyjd';
export var modalContentWrapper = '_1cm6qyjc';
export var modalDescription = '_1cm6qyjg';
export var modalHeader = '_1cm6qyjf';
export var modalOverlay = '_1cm6qyjb';
export var modalVTScope = '_1cm6qyj3';
export var widthVar = 'var(--_1cm6qyj0)';