import "../../core/src/components/doc-properties/table.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/doc-properties/table.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE51WwW7bMAy99yuEAgOSgzLHSbNUXYFhP7APGHpgLNrRZkuCJDvJhv77IMn2lNRZ250SSeTj4xNJa3Ha6nxb5+T3DSGUxlXGyHKT6eNDsrdkpAMzoxTKUkikpZKO2mbubQ6Cuz0jyyz74JfhqIRG1KdJJ5C2Pw7eGjgXsqI75ZxqGFlufeTnm0UMvArUuLC6hhMjZY2B1x5FtXeMrHqePQoj67huwFRCjqDbuPujtU6UJ1oo6VA6RqyGAukO3QFReguoRSWpcNhYRgqUDo3fLlStzEU2XU4dHh21WCjJwcR0Qo6Hnt1dliWprKdT8b+MLCf5+TOKkgcOrbGehFZioBUFFb+QkTw7k+0uxHor7dFtE9xGMTOyicJNxN4pw9FQA1y0ttf9Lw7bqw5NQNtB8bMyqpWcXuFTwwkNTeyC87cOTQ1n9D5dV5ByYbBwQknm026bcJ0V6PHyE7GulPIYZ/veOGdlYx0Ydxl9UJBjCW3tHiZkHsPfh/BDiWeJ2k5pRrLFnT4Sq2rBr0gppBUcv0af6DrVqbFJAkWSFir8v8wjxu69GFpZEfcM1uBEhwla8TY0ow7pSAlq5ZHWdw4OqDZKo3G+w+oatEX+eOtMi7dPZDqSVDLlwcPp65X0nonxstvG4bZOKneYKessGyXv7YdrHYb2VGtODb9Yp0lytqsuE1wOE2A6H1EoOT1KeD8CBsx/AWgjmgt3DE5KQyHciZ2VZ9EjT1suE8syHDkDcqit8L9UpiHZIrcEwSIVkqo2FaKMxfKyRkY0j+DLzYHD2X3GsUq5Vxdz79rEO+yFw3nsxCPtb7GAupid2SMXTpl4PicfST7dy0f696PY7f2eV6ms1YGeGIHWqYTinu3Bzi6aooO6xae4iY12pyHx+XRTfPGlBEKiIZ3Ag1bGkVngRT4/ks0608fouUifGa88Ko42pPfs8RvkAog2QroUZ9XjXBDyTqPN/RtsIKZq/S0+3iqNsr/jF++HVR67IHHm1wM8/wHRMTFlVwkAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionContainer = 'y8p28lc';
export var backLinksMenu = 'y8p28lg';
export var backlinksList = 'y8p28l7';
export var collapsedIcon = 'y8p28lf';
export var fontSize = 'var(--y8p28l1)';
export var pageInfoDimmed = 'y8p28l5';
export var propertyActionButton = 'y8p28ld';
export var propertyConfigButton = 'y8p28le';
export var propertyRootHideEmpty = 'y8p28lh';
export var root = 'y8p28l2';
export var tableBodyRoot = 'y8p28la';
export var tableBodySortable = 'y8p28lb';
export var tableHeader = 'y8p28l3';
export var tableHeaderBacklinksHint = 'y8p28l6';
export var tableHeaderCollapseButtonWrapper = 'y8p28l4';
export var tableHeaderDivider = 'y8p28l9';
export var tableHeaderTimestamp = 'y8p28l8';