import "../../core/src/modules/peek-view/view/image-preview/index.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/peek-view/view/image-preview/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61V227bMAx971dwDwNaYAps5zrtZX8yyBaTaLUlQ5Ibt0P+fZAsx7ITp91QJEgsmqIOeXioxa/0eaeWx20Cfx4AjigOR0shTZKvPx4AuDB1yV4p7EtsnYGV4iCJsFgZCgVKi9qZfzfGiv0rKZS0KO3w6vyw6E9I/Qm1MsIKJSmw3Kiysej2C2nQUkjc80lwexwgjCFF8bI78a5wu3/Chcaicy5U2VTyLvTZZHtEy03dRoD7Za40R00046IxDndntqqmkCzWGiu31F2MwZCz4vmgVSM5hRemHwlh+72QSE5HYfFpiExBKumzrBnnQh7iKEWjjdIUaiV6uIUqnWUUUxRKEv/CB7aayb6MAw5IFpkBZAaJkEQ11rm+ESE5thRSt6qYPghJfG7LXZdosIUEO2tE29LTdgvUkOhtUvdKWmLEG1LYhKKWbl/PR2/0fqdgzFXJnVHVrBD21dUqG9cO0nW376p2EeqVR33VVlO9XKqT3WwtU7MCSY72hChnOyw6dn372PlunmnZmLUo/OaTwjcGNTFYYmH77oxO2X6A8biRstWkZXaj2dTrrGItCdJzj6HIE26D71WK/676A6sp7G5KfBVbvRrrFowqBR+nG7YNuhu0Ru6XJ1ctMUfG1YlCUrcuLUjdj1voQ84ek28Qvot0NewRb74QIU2Sq/aDI+E+pd9vN87/z9Is1LASsid1GUwXNtO6hSySeDcK0mm3MI8tBPFc3FLq7Li9Iukz78B81MnZnQyjQqyTmaTfJRK+iKpW2jKnixgGPaoX1B7MOz3oHS/ViGIUnzQ7hlm/Fy3yrnOtVRWFLMgtnqoRAu4RRGNgs9vMlyUSU0R90I7/LLbrpzEdyehGu8BKJx2HHshww0yv1PAGprfp+eFnhVwwMIVGlMAkh8con+1mV7dPPvjkRpit5jn23QZfi60lfsPY9fwXGn6cuv4JAAA=\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var captionStyle = '_1k8o3h7d';
export var cursorStyle = '_1k8o3h79';
export var dividerStyle = '_1k8o3h7a';
export var imageBottomContainerStyle = '_1k8o3h7c';
export var imageNavigationControlStyle = '_1k8o3h74';
export var imagePreviewActionBarStyle = '_1k8o3h78';
export var imagePreviewModalCaptionStyle = '_1k8o3h77';
export var imagePreviewModalCenterStyle = '_1k8o3h76';
export var imagePreviewModalCloseButtonStyle = '_1k8o3h72';
export var imagePreviewModalContainerStyle = '_1k8o3h75';
export var imagePreviewModalGoStyle = '_1k8o3h73';
export var imagePreviewModalStyle = '_1k8o3h70';
export var imagePreviewTrap = '_1k8o3h71';
export var scaleIndicatorButtonStyle = '_1k8o3h7b';
export var suspenseFallbackStyle = '_1k8o3h7e';