import "../../core/src/modules/app-sidebar/views/index.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/app-sidebar/views/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VV22obMRB991cMhkICldlNYicoBEq/oO+lFHk1Xk+ilYQk31r870XSrrO7jktC6ZPXGo3OmTNHo9nPcj0vXp6LAn5PAKyQknTNliYE03B4sPvHyXEyO+36LkUQbC08Wxon0T0Ft8EfKTcvMEf1OnAoZnO7B28USdgKd8WYWK1II9veMCUO6BhpTxK/5qycfP0WGHm2UkYE0vXTNMJNWzxRvdTObLRklVHG8Qs4vX3WUSPcYQhT/kcYgDVmPSqhqquyKD4Bg0Vh9ynYCFeTZsFYDvObqPVpTeEqcLjLa0uzZ34tpNmN0K2xZouuDV4/9togJG08h8Wog5erPQVQYRWc0QMZujpiCWPq7O6DIJUi1KGz0D+qHQVQ4vBNaFTv7exfaHC+xJVxmOhURgfUgcN0Gou2xlMgozmIpTdqEzCukvYYHR+/jRUVhcOItjbkkbWxz1DkRr2W4NCiCBzy7yjo6RdymBetFV4D1IgaO6QM8RodKnGTqtmRDGsOt63TupZ2/1cKo80c6ZdUTS//NuVL8laJA087Y4ZQVGtGARvPoUId0D0m1dSm0awWtju8d9RdHjQnJR0qEWiblGwJdh4be+4MPzGW5LDKZ2XgAdw8wcWNHAooQGyC6Z/dXbt28nEo7B7KRV48A3ze+ECrAzv5wltRIVti2CHqi5L0+Cx6fEooz/j050Tkdv2hwgGc2WXhx3fyPgEHJ3SnfOtHmJVzP/Bukb1OkTzDLergOWijcXgHVrRHGZfSEEhJeWqlTzfsXfeiFEMTv+N+N0aK0cW+z/fXWNRn0+vNIX4qrXyrtK4H3dMVv38x0jI26XaA/JCOGzQ59QK17E3FrtjW+18alCTAOtIh5c+Gr26vwZ3KPQIscT720+4vpR0nxz9Wpp4s0gcAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var floatingMaxWidth = 768;
export var hoverNavWrapperStyle = '_1h50kj01';
export var navBodyStyle = '_1h50kj06';
export var navHeaderButton = '_1h50kj02';
export var navHeaderNavigationButtons = '_1h50kj03';
export var navHeaderStyle = '_1h50kj05';
export var navStyle = '_1h50kj04';
export var navWrapperStyle = '_1h50kj00';
export var resizeHandleShortcutStyle = '_1h50kj08';
export var sidebarFloatMaskStyle = '_1h50kj07';