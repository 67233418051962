import "../../core/src/desktop/dialogs/setting/general-setting/plans/ai/ai-plan.css.ts.vanilla.css!=!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/general-setting/plans/ai/ai-plan.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61U227bMAx971cQBQZsDypi172pQF/2IYMs0Y4wWzIoOU039N8Hy0pm2cmaZnkyREs8hzw8vPmR6ZUVuVnB7yuA0pJC4pB1W3C20Qo2gr4yJqpKG2TjbyZtY+nb8/4+I6F07zhk9912CHdCKW1qDrch8H51s4PJAozSrmvEG4eqwfBg+DKlCaXX1nCQtulbM/ypRcfhcUzbCqq1YaX13rYcsnyWPA/JK2s8e0Vdrz2Hu9XqeRdz+hfytKAQ37pQSzME1vFdvhohQ6mzRyUJo/ZNmODfnofv2gP4+XF8j1vPOtKtoLdDNIpA4+SXM8b3C8Z5bH9CcKHs3VnKZrFO0ejaMO2xdRycF+QPCJ4XM8z7w5iTzJPLD+Fyqw171cqvh2mNIu9rehjP86mO9V9E2KyIzUTvkZjrhAxOYdmXhO3jv0XEtlsLp91ExWg61mDlF1ZkFOHnsj39ndkP/JGUX4zln+yZQNqhtEYdmdoneAHx2ckNYYXSkhjHqzcKaWCV5Bb/MZuTNOVl0sgLromPWr5s2YJ+Yj2JxiPtuc8NJ+EF3KY+KlO6GgGi0/KZ0cbzJK86q7NzdrhsbHH6/v/E2M5VKFKvjQ7crfDOOj3SJ2yE15t0NpHzEitLGJtqPBrP4fp60r8ibV9xcE1FuFLInzXZ3igOsidC478PtFMqonS26T2OqygsjKiRt93u8P4HTY2RqR4IAAA=\"}!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionBlock = '_1i0oa2n5';
export var actionButtons = '_1i0oa2n6';
export var agreement = '_1i0oa2n9';
export var benefitGroup = '_1i0oa2nb';
export var benefitItem = '_1i0oa2ne';
export var benefitList = '_1i0oa2nd';
export var benefitTitle = '_1i0oa2nc';
export var benefits = '_1i0oa2na';
export var card = '_1i0oa2n0';
export var learnAIButton = '_1i0oa2n8 _1i0oa2n7';
export var purchaseButton = '_1i0oa2n7';
export var title = '_1i0oa2n4';
export var titleBlock = '_1i0oa2n1';
export var titleCaption1 = '_1i0oa2n2';
export var titleCaption2 = '_1i0oa2n3';