import "../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VWbW/bIBD+nl+BIk1qpBLhNOlaV/0h09QPGM4OCwYLsJN02n+fjB2HOMRbp+6bDdzbc3fP3ZIl9YOsCPo5Q2gLoti6FG1IdXiZIcSFrSQ9piiX4A+oFIXCwkFpU8RAOTDtcUGrFD11MhXlXKgiRQQ9dieZNhwMNpSL2qZo3Z3q2kmhIEVKK2gP9oK7bYoSQr60vyU94P5oQ3p/mJbapKih5g5jmudCAW5W2MHB4cqIkprj4mX2a7bsg0q3ugHjQ2O1sa1spcXJ64yyXWF0rfhIpZfC3lioLvGKep9Iq6GFJUXJP0OlrXBCqxQZkNSJBgJrK2+t1Ybt1gi18zaH6wd/3UeDoQHl7BlLXVEm3DFFZPkUCkUA4ZDTWroxIJ2mQXJ9UR/rVZ/DBkwu9T5FW8E5qEBg4wWuUPHxcGGAdYEzLetSXeHVhe2o8X45Q5XNtSnT7lNSB9/uyGK461Ec3iGy3FjE6kwwnMG7AHO3XG/ul6vkntwnC0RsGNp3Th3FlLUJeJ07U8P8DV2EEXcAtzCEBfIYFsipjn0ohWlBSuKYXeP6Adh+1NaJ/IiZVg6UC0sthKYviM8D5rG7dMcKXudKm5LK+ZuP/1x7t3XlVNpbyrpanlL2tWsNrRy24h1G3dudl746pG/nHt1Vj65/sB+ozndybcFgCxKYC7ookirPNecLkFJUVlhPYFvhANuKMs9qe0OrCK8NuRXKe5dJzXZBcE/xxvG80Qdwg1omATnYCCAkAsi6AyRGtZmkbIc3ZHHhrm2Ki7rvWf9k5PQfUyiYVtgC04qPqPv5/6AwVRYxB322BwevR8Lzp0YfBDyuCvrnkp/KcDCU+9lTCjV6NdjKPkrdExwUTMiJYcFu9/pphsdfJsFL3o21+IoQwToAM1lfAABn0u/pk0qJyDJ5sAioDbcVEib7hHaMN0pqCqGwhNz1Un+7MUygW0nK4FpiCoPzmnRrMct0u3FQ3vp/YsJwM/C4VNSA8sP5arG6lToI++QEVWjskmtq59qU+bvF5LrWrIbXMSrJ+01pyGVmtaydj8t0iYvnI1zVorm5FenYYmS7K8b9nKwnV1yfOz89qQPehvcb5t98x7kLAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var activeContainer = 'c1u3lp2';
export var activeIcon = 'c1u3lpg';
export var collaborationIcon = 'c1u3lpd';
export var container = 'c1u3lp0';
export var disable = 'c1u3lp3';
export var enableCloudButton = 'c1u3lpc';
export var infoContainer = 'c1u3lp1';
export var settingButton = 'c1u3lpe';
export var showOnCardHover = 'c1u3lpf';
export var workspaceActiveStatus = 'c1u3lp9';
export var workspaceInfo = 'c1u3lp6';
export var workspaceInfoSlide = 'c1u3lp5';
export var workspaceInfoSlider = 'c1u3lp4';
export var workspaceInfoTooltip = 'c1u3lpa';
export var workspaceName = 'c1u3lp7';
export var workspaceStatus = 'c1u3lp8';
export var workspaceTitleContainer = 'c1u3lpb';